@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/fonts";

html{
	height: 100%;
}

body{
    font-family:  $Averta;
	font-weight: 400;
    font-style: normal;
    font-size: 16px;
	letter-spacing: 0.5px;
    line-height: 1.43;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	background-color: transparent;
}

.row{
	height: 100%;
}

@import "pages/home";
@import "pages/videojs-landio-skin";
@import "pages/animate.scss";
@import "icomoon";
