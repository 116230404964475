body{
	font-family: 'Poppins', sans-serif;
}
h1{
	font-weight: 600;
	font-size: 36px;
}
h3{
	font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: $night-blue;
}
.container-fluid{
      .navbar-brand{
         position: relative;
         bottom: 8px;
		 padding: 10px 15px;
        .icon-upteamist{
            fill: #186df1;
            width: 70px;
            height: 40px;
        }
        .upteamist-word{
            font-size: 22px;
            display: inline-block;
            vertical-align: middle;
            color: #186df1;
        }
     }
    .background-splash{
        height: 580px;
        background-repeat: no-repeat;
        object-fit: contain;
        position: relative;
        .screens-min{

        }
		.free-register-all{
			padding:0;
				.group-fre-register{
					.fre-header{
						 font-family: 'Poppins', sans-serif;
						  font-size: 36px;
						  line-height: 1.22;
						  text-align: left;
						  color: $white;
						  padding-top: 60px;
						  padding-bottom: 5px;
						  padding-left: 15px;
						  padding-right: 15px;
						  font-weight: 800;
					}
					 .box-regis{
						  border-radius: 2px;
						  border: solid 1px white;
						  font-family: 'Poppins', sans-serif;
						  font-size: 16px;
						  line-height: 1.5;
						  text-align: left;
						  color: $night-blue;
						  padding: 8px 44px 8px 10px;
						  width: 100%;
						  margin-bottom: 16px;
						  line-height: 1;
						  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
							   color: rgba($night-blue, 0.25);
							}
							&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
								color: rgba($night-blue, 0.25);
							}
							&::-moz-placeholder { /* Firefox 19+ */
								color: rgba($night-blue, 0.25);
							}
							&:-moz-placeholder { /* Firefox 18- */
								color: rgba($night-blue, 0.25);
							}
						}
					.pre-regis-box{
						  font-family: 'Poppins', sans-serif;
						  font-size: 16px;
						  font-weight: 600;
						  line-height: 1.5;
						  text-align: center;
						  color: $white;
						  background-color:$azure;
						  padding: 0;
						  width: 100%;
						  border: 1px;
						  border-radius: 2px;
							.overlay-btn{
								padding: 8px 25px;
							}
							&:hover{
								border-color: rgba(245, 245, 245, 0.3);
								transition: all 0.3s linear;
								.overlay-btn{
									background-color: rgba(245, 245, 245, 0.3);
									transition: all 0.3s linear;
								}
							}
							&:focus{
								border-color:#76abff;
								transition: all 0.3s linear;
								.overlay-btn{
									background-color: inherit;
									transition: all 0.3s linear;
								}
							}
							&:active{
								border-color:rgba(#000000, 0.15);
								transition: all 0.3s linear;
								.overlay-btn{
									background-color: rgba(#000000, 0.15);
									transition: all 0.3s linear;
								}
							}
				}
				.group-condition{
					padding-bottom: 32px;
					padding-left: 15px;
					padding-right: 15px;
						.bot-word{
							  font-size: 14px;
							  line-height: 1.43;
							  text-align: left;
							  color: $white;
							  display: inline-block;
						}
						.bot-blue{
							  font-size: 14px;
							  line-height: 1.43;
							  text-align: left;
							  color: $azure;
							  display: inline;
							  cursor: pointer;
						}
					}
				}
		}
    }
         .collaborate{
            margin-top: 80px;
            .collaborate-header{
                  padding-bottom: 85px;
                  font-size: 36px;
                  line-height: 1.5;
                  text-align: center;
                  color: #071c40;
            }
            .group-img{
                 text-align: left;
                .secured-img{
                      object-fit: contain;
					  height: 250px;
					  padding-bottom: 12px;
                }
                .accessible-img{
                      object-fit: contain;
					  height: 250px;
					   padding-bottom: 12px;
                }
                .simple-img{
                      object-fit: contain;
					  height: 250px;
					  padding-bottom: 12px;
                }
                .header-blue{
                      font-size: 24px;
                      font-weight: bold;
                      line-height: 1.5;
                      text-align: left;
                      color: $azul;
                }
                .upteamist-word{
                      font-size: 16px;
                      line-height: 1.5;
                      text-align: left;
                      color:  rgba($night-blue, 0.5);
					  padding-top: 12px;
					  margin-bottom: 80px;
                }
            }
   }
}
 .screen-min-img{
	width: 650px;
	position: relative;
	top: 40px;
 }
.navbar-default .navbar-toggle{
	&.navbar-custom{
		border-color: transparent;
	}
}
.navbar-toggle .icon-bar{
	&.icon-custom{
		height: 4px;
		border-radius: 6px;
	}
}
.navbar-toggle .icon-bar{
	&.icon-long{
		height: 4px;
		width: 26px;
		border-radius: 6px;
	}
}


.background-slider{
	.basic-img{
		text-align:center;
			img{
				width: 140px;
				height: 143px;
			}
	}
}
@media only screen and (max-width: 768px){
.row{
		.bg-image{
			height: auto;
			width: 100%;
			margin-left:0;
			margin-right:0;
			background-repeat: no-repeat;
			background-position: 88% 39%;
			background-image: linear-gradient(90deg, rgba(#186df1, 0.8), rgba(#00069d, 0.8));
			margin-top: 51px;
		}
	}
}

@media only screen and (min-width: 768px){
.row{
	&.bg-image{
			height: auto;
			width: 100%;
			margin-left:0;
			margin-right:0;
			background-repeat: no-repeat;
			background-position: 70% 40%;
			background-image: linear-gradient(90deg, rgba(#186df1, 0.8), rgba(#00069d, 0.8));
		}
	}
}


.navbar.navbar-default{
    padding: 20px 0;
	//background-color: $white;
	opacity: 0.8;
	background-image: linear-gradient(85deg, #186df1, #00069d);
}

.navbar-default .navbar-toggle .icon-bar{
	background-color: $azul;
}

.container>.navbar-header, .container-fluid>.navbar-header, .container>.navbar-collapse, .container-fluid>.navbar-collapse{
	margin-right: 0;
    margin-left: 0;
}

.navbar.navbar-default{
          border:none;
		  border-radius: 0;
          margin-bottom: 0px;
		  //background-image: linear-gradient(107deg, rgba(#186df1, 0.8), rgba(#00069d, 0.8));
}
    .nav.navbar-nav.navbar-right{
		margin-top:5px;
        .sign-in{
            font-size: 16px;
            font-weight: 600;
			font-family: 'Poppins', sans-serif;
            line-height: 1.5;
            color: $azul;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            position: relative;
            display: block;
			border: 0;
			border-radius: 4px;
        }
        .sign-up{
            border: 0;
            border-radius: 2px;
			font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.43;
            text-align: center;
            color: $white;
            position: relative;
            display: block;
            cursor: pointer;
			max-width: 400px;
			margin: auto;
			padding-left: 10px;
				&:first-child{
					padding-left: 0;
				}
		}

		.cta-primary{
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				background-color: $azul;
				vertical-align: middle;
				width: 100%;
				border: 1px;
				padding: 0;
				border-radius: 4px;
				transition: all 0.3s linear;
				.overlay-btn{
					padding: 9px 25px;
				}
				&:hover{
					border-color: rgba(245, 245, 245, 0.3);
					transition: all 0.3s linear;
				.overlay-btn{
					background-color: rgba(245, 245, 245, 0.3);
					transition: all 0.3s linear;
				}
			}
				&:focus{
					border-color:#76abff;
					transition: all 0.3s linear;
				.overlay-btn{
					background-color: inherit;
					transition: all 0.3s linear;
				}
			}
				&:active{
					border-color:rgba(#000000, 0.15);
					transition: all 0.3s linear;
					.overlay-btn{
						background-color: rgba(#000000, 0.15);
						transition: all 0.3s linear;
					}
				}
			}

		.cta-primary-white{
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				color: $white;
				background-color: transparent;
				vertical-align: middle;
				width: 100%;
				border: 1px solid $white;
				padding: 0;
				border-radius: 4px;
				transition: all 0.3s linear;
					.overlay-btn{
						padding: 8px 25px;
					}
					&:hover{
						border-color: rgba(245, 245, 245, 0.3);
						transition: all 0.3s linear;
						border: 1px solid $azul;
					.overlay-btn{
						background-color: rgba(245, 245, 245, 0.3);
						transition: all 0.3s linear;
						color: $white;
						background-color: $azul;
						}
					}
					&:focus{
						border-color:#76abff;
						transition: all 0.3s linear;
						border: 1px solid $azul;
					.overlay-btn{
						background-color: inherit;
						transition: all 0.3s linear;
						color: $white;
						background-color: $azul;
						}
					}
					&:active{
						border-color:rgba(#000000, 0.15);
						transition: all 0.3s linear;
						border: 1px solid $azul;
						.overlay-btn{
							background-color: rgba(#000000, 0.15);
							transition: all 0.3s linear;
							color: $white;
							background-color: $azul;
						}
					}
			}
	}

.group-radio-noti{
     background-image: linear-gradient(245deg, $azure, #1564e0);
     width: 100%;
     height: 100%;
	 padding: 0;
	 .rectangle-bg{
		padding: 0;
		text-align: center;
	 }
    .radio-check {
		input[type="radio"] {
		position: absolute;
		opacity: 0;
    + .radio-label {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 1.5;
		text-align: left;
		color: $white;
		font-weight: 400;
      &:before {
        content: '';
        border-radius: 100%;
        border: 1px solid $white;
        display: inline-block;
        width: 17px;
        height: 17px;
        position: relative;
        margin-right: 12px;
		top: 1.7px;
        vertical-align: top;
        text-align: center;
      }
    }
	&:checked + label:after{
		color: $azul;
	}
    &:checked {
      + .radio-label {
        &:before {
          background-color: $azul;
          box-shadow: inset 0 0 0 4px $white;
		  border:1px solid $azul;
        }
      }
    }
	 &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $azul;
        }
      }
    }
	+ .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
    .group-radio{
        margin-top:100px;
    }
    .imgs-koitech-nofi{
        width:100%;
        margin-bottom: 88px;
		margin-top: 72px;
    }
	 .imgs-koitech-simply{
        width:100%;
        margin-bottom: 88px;
		margin-top: 72px;
		display:none;
    }
	 .imgs-koitech-search{
        width:100%;
        margin-bottom: 88px;
		margin-top: 72px;
		display: none;
    }
	 .imgs-koitech-res{
        width: 100%;
		max-width: 500px;
		display:none;
    }
    .rectangle{
        width:100%;
        border-radius: 6px;
        background-color: #e4e4e4;
    }
}

.background-column{
		border-radius: 2px;
		background-color: $white;
		border: solid 2px rgba(7, 28, 64, 0.1);
		padding: 20px 10px 10px 10px;
		transition: all 0.3s linear;
		&:hover{
		border-color: $azul;
	}
	.basic-img{
		text-align:center;
			img{
				width: 140px;
				height: 143px;
			}
	}
}

.background-column-middle{
		border-radius: 2px;
		background-color: $white;
		border: solid 2px rgba(7, 28, 64, 0.1);
		padding: 20px 10px 10px 10px;
		transition: all 0.3s linear;
		&:hover{
		border-color: $azul;
	}
}
.active-side{
	height: 460px;
	margin-top: 0;
}
.border-middle{
	transform: translateY(-50%);
    padding: 0;
	margin-top: 300px;
	transition: all 0.3s linear;
}
.border-two-side {
    margin-top: 10px;
    padding: 0;
}
.border-two-side-left {
    margin-top: 10px;
    padding: 0;
}
.border-two-side-right {
    margin-top: 10px;
    padding: 0;
}

.basic-word{
	font-size: 36px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	color: $azul;
}


.free-word{
	font-size: 60px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	color: $night-blue;
    position: relative;
	margin-bottom: 20px;
		.number-1{
			display: inline-block;
		}
		.dollar-word-1{
			font-size: 24px;
			line-height: 1.5;
			text-align: center;
			color: $night-blue;
			position: relative;
			top: -16px;
			display: inline-block;
            padding-left: 40px;
		}
		.number-2{
			display: inline-block;
		}
		.dollar-word-2{
			font-size: 24px;
			line-height: 1.5;
			text-align: center;
			color: $night-blue;
			position: relative;
			top: -16px;
			display: inline-block;
            padding-left: 50px;
		}
        .mo{
            font-size: 20px;
            font-weight: 600;
            line-height: 1.6;
            text-align: center;
            color: $night-blue;
            display: inline-block;
            position: relative;
        }
}

.trial-word{
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
	color: $azul;
	margin-bottom: 10px;
}

.box-get-side{
    .cta-side-box{
		padding: 0;
        margin: 0 auto;
		display: none;
		border: 1px;
		border-radius: 2px;
        width: 90%;
        background-color: $azul;
		transition: all 5s linear;
    }
    .overlay-side-btn{
        padding: 8px 24px;
	    font-size: 20px;
		font-weight: 600;
		line-height: 1.6;
        color: $white;
		transition: all 0.3s linear;
    }
	&:hover{
		border-color: rgba(245, 245, 245, 0.3);
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
		}
	}
	&:focus{
		border-color:#76abff;
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: inherit;
			transition: all 0.3s linear;
		}
	}
	&:active{
		border-color:rgba(#000000, 0.15);
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: rgba(#000000, 0.15);
			transition: all 0.3s linear;
		}
    &.active {
	    color: $white;
	    background-color: $azul;
		transition: all 0.3s linear;
    }
}
}
.box-get-started{
    .cta-middle-box{
        margin: 0 auto;
		display: none;
		padding: 0;
        width: 90%;
		border-radius: 2px;
        border: 1px;
        background-color: $azul;
		transition: all 5s linear;
		.overlay-btn{
			padding: 8px 24px;
			font-size: 20px;
			font-weight: 600;
			line-height: 1.6;
			color: $white;
			font-family: 'Poppins', sans-serif;
			transition: all 0.3s linear;
		}
	&:hover{
		border-color: rgba(245, 245, 245, 0.3);
		transition: all 0.3s linear;
		.overlay-btn{
			background-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
		}
	}
	&:focus{
		border-color:#76abff;
		transition: all 0.3s linear;
		.overlay-btn{
			background-color: inherit;
			transition: all 0.3s linear;
		}
	}
	&:active{
		border-color:rgba(#000000, 0.15);
		transition: all 0.3s linear;
		.overlay-btn{
			background-color: rgba(#000000, 0.15);
			transition: all 0.3s linear;
		}
    &.active {
	    color: $white;
	    background-color: $azul;
		transition: all 0.3s linear;
    }
}
}
}

.manage-sub{
       padding: 70px 30px;
	   height: 860px;
       .manage-sub-header{
            padding-bottom: 24px;
            font-size: 36px;
            line-height: 1.5;
            text-align: center;
            color: $night-blue;
      }
}

.group-grey{
    text-align: center;
    background-color: rgba(7, 28, 64, 0.05);
    padding: 70px;
    .header-grey{
        font-size: 36px;
        font-weight: 800;
        line-height: 1.5;
        color: rgba($night-blue, 0.5);
    }
    .group-logo{
        .arigato{
            display:inline-block;
            padding: 20px;
        }
        .niftit{
            display:inline-block;
            padding: 20px;
        }
        .hufr{
            display:inline-block;
            padding: 20px;
        }
        .wesport{
            display:inline-block;
            padding: 20px;
        }
        .partage{
            display:inline-block;
            padding: 20px;
        }
        .yes-young{
            display:inline-block;
            padding: 20px;
        }
    }
}


.group-subscribe{
    padding:70px;
    .group-subscribe-word{
		text-align: center;
        .black-word{
            font-size: 36px;
			font-weight: 800;
            line-height: 1.5;
            text-align: left;
            color: $night-blue;
            display:inline-block;
        }
        .blue-word{
             font-size: 36px;
             font-weight: 800;
             line-height: 1.5;
             text-align: left;
             color: $azure;
             display:inline-block;
        }
        .small-word{
             font-size: 16px;
             line-height: 1.5;
             color: $night-blue;
			 margin-bottom: 20px;
			 margin: auto;
			 max-width: 490px;
			 padding-bottom: 20px;
        }
    }
    .group-subscribe-button{
        padding: 45px 0px;
		float:  right;
        .subscribe{
            padding: 13px 19px;
            display:inline-block;
			line-height: 0.4;
			min-width: 322px;
			color: rgba($night-blue, 0.25);
            border-radius: 2px;
            border: solid 1px rgba($night-blue, 0.25);
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: rgba($night-blue, 0.25);
			  }
			  ::-moz-placeholder { /* Firefox 19+ */
				color: rgba($night-blue, 0.25);
			  }
			  :-ms-input-placeholder { /* IE 10+ */
				color: rgba($night-blue, 0.25);
			  }
			  :-moz-placeholder { /* Firefox 18- */
				color: rgba($night-blue, 0.25);
			  }
		}
        .box-subscribe{
            display: inline-block;
            vertical-align: top;
            .cta-subscribe{
                border: solid 2px $azul;
                background-color: $azul;
                .btn-subscribe{
                    padding: 5px 24px;
	                font-size: 20px;
		            font-weight: 600;
		            line-height: 1.4;
                    color: $white;
                }
              }
            }
        }
    }

.footer{
    background-color: $night-blue;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    padding: 70px 0;
    .left-footer{
          font-size: 16px;
          line-height: 1.5;
          color: $white;
          line-height: 40px;
    }
	.right-footer{

	}
	.social-footer{
			.social-nav{
				list-style-type: none;
				float:right;
					.social-nav-li{
					    display: inline-block;
					    padding-right: 15px;
					    line-height: 52px;
							svg{
								width: 24px;
								height: 24px;
								fill:$white;
							}
					}
			}
	}
}

.navbar-nav{
	text-align: left;
}

.mailchimp{
	text-align: center;
    max-width: 500px;
    margin: auto;
}

.navbar-default .navbar-nav>li>a{
	color: rgba($white, 0.75);
	&:hover{
		color: $white;
	}
	&:focus{
		color: $white;
	}
	&:active{
		color: $white;
	}
}

.active-menu{
		font-weight: 600;
		color: $night-blue;
	}

.navbar-nav>li>a{
    color: $white;
}


.active-column{
	border: 2px solid $azul;
	box-shadow: 0 2px 10px 0 $azul;
		.box-get-started .cta-middle-box{
			display:block;
			transition: all 5s linear;
		}
		.box-get-side .cta-side-box{
			display:block;
			transition: all 5s linear;
		}
}


.play-btn{
    border-radius: 90px;
    border: 1px solid #ADAAA7;
    height: 100px;
    position: absolute;
    width: 100px;
    margin: auto;
    top:0;
    bottom:0;
    right:0;
    left:0;
    cursor: pointer;
}
.play-btn:before{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 50px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top:0;
    left:0;
    right:-10px;
    bottom:0;
    margin: auto;
}

.background-video{
    background-color: rgba(#071c40, 1);
	padding: 70px 0px;
	.video {
     position: relative;
     width: 100%;
	 transition: width 2s, height 2s, background-color 2s, transform 2s;
	 }
}

.wrapper{
	text-align: center;
	&:hover{
		opacity: 0.5;
		transition: all 0.5s ease;
	}
		.bg-video{
			width:100%;
			max-width: 900px;
			margin:auto;
		}
		.svg-video{
			width: 90px;
			height: 90px;
			top: 50%;
			left: 50%;
			cursor: pointer;
			transform: translate(-50%, -50%) scale(0.9);
			margin: auto;
			transition: all 0.5s ease;
			position: absolute;
			fill: rgba($white, 0.5);
			&:hover{
				    transform: translate(-50%, -50%) scale(1);
					transition: all 0.5s ease;
			}
		}
}
.wrapper-after{

	}

.playpause{
    border-radius: 90px;
    border: 1px solid #ADAAA7;
    height: 100px;
    position: absolute;
    width: 100px;
    margin: auto;
    top:0;
    bottom:0;
    right:0;
    left:0;
    cursor: pointer;
}
.playpause:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 50px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top:0;
    left:0;
    right:-10px;
    bottom:0;
    margin: auto;
}


 .oval-video{
        border-radius:100%;
        width: 6px;
        height: 6px;
        background-color: #9b9b9b;
        display: inline-block;
        position: relative;
        top: 19px;
        left: 10px;
    }
.rectangle-video{
        width:100%;
		height: 18.6px;
        border-radius: 6px;
        background-color: #e4e4e4;
}


.white-bg-1{
	max-width: 360px;
    height: 165px;
    background-color: white;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.white-bg-2{
	max-width: 360px;
    height: 220px;
    background-color: white;
	display:none;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.white-bg-3{
	max-width: 360px;
    height: 220px;
    background-color: white;
	display:none;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.white-bg-4{
	max-width: 360px;
    height: 220px;
    background-color: white;
	display: none;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}

.radio-item {
  position: relative;
  padding: 0;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: $white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  padding-left: 15px;
  cursor: pointer;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 17px;
  height: 17px;
  border-radius: 11px;
  border: 2px solid $white;
  background-color: $azul;
  margin-right: 15px;
}
.radio-item input[type=radio]:checked + label:before{
	border: 2px solid $azul;
	background-color: white;
	vertical-align: top;
}
.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 20px;
  content: " ";
  display: block;
  background: $azul;
  border: 1px solid $azul;
  vertical-align: top;
}
.radio-item input[type=radio]:checked + label {
  color: $azul;
  position: absolute;
  margin-top: 15px;
  padding-left: 15px;
}

.content-1{
	position: absolute;
    top: 35px;
    max-width: 320px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $night-blue;
	padding: 0px 0px 0px 46px;
	margin-top: 12px;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}

.content-2{
	position: absolute;
    top: 35px;
    max-width: 320px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $night-blue;
	display: none;
	padding: 0px 0px 0px 46px;
	margin-top: 12px;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.content-3{
	position: absolute;
    top: 35px;
    max-width: 320px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $night-blue;
	display: none;
	padding: 0px 0px 0px 46px;
	margin-top: 12px;
	transition: width 2s, height 2s, background-color 2s, transform 2s;
}
.content-4{
	position: absolute;
    top: 35px;
    max-width: 320px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $night-blue;
	display: none;
	padding: 0px 0px 0px 46px;
	margin-top: 12px;

}


.footer-nav {
  list-style-type: none;
  text-align: center;
  float: none;
  padding-left:0;
}

.footer-nav-li {
  color: white;
  display: inline-block;
  padding-right: 15px;
  line-height: 40px;
}


a{
	color:white;
	&:-webkit-any-link{
		text-decoration: none;
	}
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background-color: transparent;
}

@media (max-width: 1110px) and (min-width: 991px) {
	.screen-min-img{
		width: 650px;
		position: relative;
	}
	.background-all .collaborate .group-img .secured-img{
		position: relative;
		left: 12px;
	}
}
@media only screen and (max-width: 991px) {
	.container-fluid .background-splash{
		height: auto;
	}
	.container-fluid .background-splash .group-fre-register{
		max-width: none;
	}
	.container-fluid .background-splash .group-fre-register .fre-header{
		padding-top: 0 !important;
		font-size: 24px;
	}
    .container-fluid .background-splash .group-fre-register{
		max-width: none;
		padding-top: 20px;
	}
	.group-subscribe .group-subscribe-button{
		float: none;
	}
	.background-all .collaborate .group-img .secured-img{
		position: relative;
		left: 10px;
	}
	.footer{
		height: 60px;
	}
	.footer .left-footer{
		text-align: center;
		line-height: normal;
	}
	.footer .right-footer{
		display:none;
	}
	.footer .social-footer{
		display:none;
	}
	.footer-nav{
		text-align: center;
		float: none;
	}
	.screen-min-img{
		padding-top: 10px;
		max-width: 295px;
		margin: auto;
		display: block;
	}
	.background-all .background-splash .screens-min{
		top: 20px;
	}
	.background-all .background-splash .group-fre-register .fre-header{
		padding-top: 0;
	}
	 .group-subscribe .group-subscribe-button .subscribe{
		width: 100%;
		margin-bottom: 10px;
	 }
	 .group-subscribe .group-subscribe-button .box-subscribe{
		width: 100%;
	 }
	 .group-subscribe .group-subscribe-button .box-subscribe .cta-subscribe{
		width: 100%;
	 }
	 .group-radio-noti .group-radio{
		padding: 0 250px;
	 }
	 .border-two-side{
		margin: unset;
	 }
	 .border-two-side-left {
		margin-top: 10px;
		padding: 0;
	 }
	 .border-two-side-right {
		margin-top: 10px;
		padding: 0;
	 }
	 .screen-min-img{
		position: static!important;
	}
	.rectangle-bg-2{
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
	 }
	 .oval-2{
        border-radius:100%;
        width: 6px;
        height: 6px;
        background-color: #9b9b9b;
        display: inline-block;
        position: relative;
        top: 19px;
        left: 10px;
        margin-top: 72px;
    }
    .imgs-koitech-2-nofi{
        width:100%;
		margin-top: 72px;
        margin-bottom: 88px;
    }
	 .imgs-koitech-2-simply{
        width:100%;
		margin-top: 72px;
        margin-bottom: 88px;
		display:none;
    }
	 .imgs-koitech-2-search{
        width:100%;
		margin-top: 72px;
        margin-bottom: 88px;
		display:none;
    }
	 .imgs-koitech-2-res{
        width:100%;
		max-width: 400px;
		display:none;
    }
    .rectangle-2{
        width:100%;
        border-radius: 6px;
        background-color: #e4e4e4;
		height: 12px;
    }
	.rectangle-bg{
		display:none;
	}
	.group-radio-noti .group-radio{
		margin-top: 0;
		margin-bottom: 100px;
	}
	.manage-sub{
		height: auto;
	}
	.container-fluid .collaborate .group-img{
		text-align: center;
	}
	.container-fluid .collaborate .group-img .header-blue{
		text-align: center;
	}
	.container-fluid .collaborate .group-img .upteamist-word{
		text-align: center;
		max-width: 450px;
		margin: auto;
		padding-bottom: 20px;
	}
}


@media only screen and (max-width: 767px) {
	.navbar.navbar-default{
		padding: 0;
	}
	.container-fluid .navbar-brand{
		bottom: 4px;
	}
	.navbar-default .navbar-nav > li > a{
		width: 100px;
	}
	.nav>li{
		margin: 0 15px;
	}
	.nav.navbar-nav.navbar-right .sign-in{
			border: 1px solid;
			border-radius: 2px;
			border-color: $azul;
			cursor: pointer;
			margin-bottom: 16px;
			margin: auto;
			margin-right: 15px;
			margin-left: 15px;
			border:0;
		}
	.nav.navbar-nav.navbar-right .sign-up{
		max-width: none;
		//margin-top: 16px;
		margin-right: 15px;
		margin-left: 15px;
	}
	.exit-img{
		display:none;
	}
	.navbar-brand{
		display:none;
	}
	.navbar-exchange{
		float: left;
		padding: 15px 20px;
		font-size: 18px;
		line-height: 20px;
		height: 50px;
	}

}
@media only screen and (min-width: 768px) {
	.navbar-exchange{
		display:none;
	}
	.navbar-right{
		margin-right: 0;
	}
}

@media (max-width:1190px) and (min-width:991px) {
    .group-subscribe .group-subscribe-button .subscribe{
		min-width: unset;
	}
}

@media (max-width:673px) and (min-width:0px) {
    .group-subscribe .group-subscribe-button .subscribe{
		min-width: unset;
	}
}

//* radio responsiveness
@media (max-width:1145px) and (min-width:991px) {
	.group-radio-noti .group-radio{
		padding-left: 0;
		padding-right: 0;
	}
}

@media (max-width:890px) and (min-width:850px) {
	.group-radio-noti .group-radio{
		padding: 0 215px;
	}
}

@media (max-width:850px) and (min-width:765px) {
	.group-radio-noti .group-radio{
		padding: 0 190px;
	}
}

@media (max-width:765px) and (min-width:750px) {
	.group-radio-noti .group-radio{
		padding: 0 180px;
	}
}

@media (max-width:750px) and (min-width:730px) {
	.group-radio-noti .group-radio{
		padding: 0 170px;
	}
}

@media (max-width:730px) and (min-width:690px) {
	.group-radio-noti .group-radio{
		padding: 0 150px;
	}
}

@media (max-width:690px) and (min-width:650px) {
	.group-radio-noti .group-radio{
		padding: 0 130px;
	}
}

@media (max-width:650px) and (min-width:570px) {
	.group-radio-noti .group-radio{
		padding: 0 100px;
	}
}

@media (max-width:570px) and (min-width:475px) {
	.group-radio-noti .group-radio{
		padding: 0 50px;
	}
}
@media (max-width: 475px) and (min-width: 0px) {
	.group-radio-noti .group-radio{
		padding: 0;
	}
}

//* iphone 6 plus
@media (max-width:736px) and (min-width:414px) {
	.navbar.navbar-default{
		padding: 0;
	}
	.background-video{
		padding: 0;
	}
	.manage-sub{
		padding: 70px 40px;
	}
	.border-two-side{
		margin-top: 0;
	}
	.border-two-side-left {
		margin-top: 10px;
		padding: 0;
	}
	.border-two-side-right {
		margin-top: 10px;
		padding: 0;
	}
	.group-grey{
		padding: 70px 3px;
	}
	.group-subscribe{
		padding: 70px 10px;
	}
	.radio-item {
		padding: 0 12px;
	}
	.container-fluid .collaborate .collaborate-header{
		font-size: 24px;
	}
	.container-fluid .collaborate .group-img .header-blue{
		font-size: 20px;
	}
	.manage-sub .manage-sub-header{
		font-size: 24px;
	}
	.group-grey .header-grey{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .black-word{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .blue-word{
		font-size: 24px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
	.navbar-default .navbar-nav > li > a{
		font-size: 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	#myPopup .modal-dialog .modal-content{
		height: auto !important;
	}
}

//* iphone 6
@media (max-width:667px) and (min-width:375px) {
	.navbar.navbar-default{
		padding: 0;
	}
	.background-all .background-splash .group-fre-register{
		padding: 20px 0px 20px;
	}
	.background-video{
		padding: 0;
	}
	.manage-sub{
		padding: 70px 40px;
	}
	.border-two-side{
		margin-top: 0;
	}
	.border-two-side-left {
		margin-top: 10px;
		padding: 0;
	}
	.border-two-side-right {
		margin-top: 10px;
		padding: 0;
	}
	.group-grey{
		padding: 70px 3px;
	}
	.group-subscribe{
		padding: 70px 10px;
	}
	.radio-item {
		padding: 0 12px;
	}
	.container-fluid .collaborate .collaborate-header{
		font-size: 24px;
	}
	.container-fluid .collaborate .group-img .header-blue{
		font-size: 20px;
	}
	.manage-sub .manage-sub-header{
		font-size: 24px;
	}
	.group-grey .header-grey{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .black-word{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .blue-word{
		font-size: 24px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
	.navbar-default .navbar-nav > li > a{
		font-size: 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	#myPopup .modal-dialog .modal-content{
		height: auto !important;
	}
}

//* Galaxy S5
@media (max-width:640px) and (min-width:360px) {
	.navbar.navbar-default{
		padding: 0;
	}
	.background-all .background-splash .group-fre-register{
		padding: 20px 0;
	}
	.manage-sub{
		padding: 70px 20px;
	}
	.border-two-side{
		margin-top: unset;
	}
	.border-two-side-left {
		margin-top: 10px;
		padding: 0;
	}
	.border-two-side-right {
		margin-top: 10px;
		padding: 0;
	}
	.group-subscribe{
		padding: 70px 10px;
	}
	.footer{
		padding: 20px;
	}
	.screen-min-img{
			max-width: 295px;
	}
	.container-fluid .collaborate .group-img .secured-img{
		position: unset!important;
	}
	.container-fluid .collaborate .group-img .accessible-img{
		position: unset!important;
	}
	.container-fluid .collaborate .group-img .simple-img{
		position: unset!important;
	}
	.radio-item {
		padding: 0 12px;
	}
	.container-fluid .collaborate .collaborate-header{
		font-size: 24px;
	}
	.container-fluid .collaborate .group-img .header-blue{
		font-size: 20px;
	}
	.manage-sub .manage-sub-header{
		font-size: 24px;
	}
	.group-grey .header-grey{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .black-word{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .blue-word{
		font-size: 24px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
	.content-2{
		padding:0 20px 0 46px;
	}
	.content-4{
		padding:0 20px 0 46px;
	}
	.navbar-default .navbar-nav > li > a{
		font-size: 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
}

//* Iphone 5
@media (max-width:568px) and (min-width:320px) {
	.navbar.navbar-default{
		padding: 0;
	}
	.container-fluid .background-splash .screens-min{
		right: 0;
	}
	.container-fluid .background-splash .group-fre-register .fre-header{
		font-size: 24px;
	}
	.container-fluid .collaborate{
		padding-left: 0;
		padding-right: 0;
	}
	.container-fluid .collaborate .collaborate-header{
		padding: 0 10px;
	}
	.container-fluid .background-splash .group-fre-register{
		padding: 20px 0;
	}
	.container-fluid .collaborate .group-img .secured-img{
		position: relative;
		margin-top: 20px;
		right: 15px;
		left: -10px!important;
	}
	.container-fluid .collaborate .group-img .accessible-img{
		position: relative;
		right: 5px;
		margin-top: 20px;
	}
	.container-fluid .collaborate .group-img .simple-img{
		position: relative;
		right: 5px;
		margin-top: 20px;
	}
	.manage-sub{
		padding: 70px 10px;
	}
	.border-two-side{
		margin-top: unset;
	}
	.border-two-side-left {
		margin-top: 10px;
		padding: 0;
	}
	.border-two-side-right {
		margin-top: 10px;
		padding: 0;
	}
	.group-subscribe{
		padding: 20px;
	}
	.footer{
		padding: 20px;
	}
	.container-fluid .navbar-brand{
		margin-right: auto;
	}
	.screen-min-img{
		max-width: 295px;
	}
	.container-fluid .collaborate .collaborate-header{
		font-size: 24px;
	}
	.container-fluid .collaborate .group-img .header-blue{
		font-size: 20px;
	}
	.manage-sub .manage-sub-header{
		font-size: 24px;
	}
	.group-grey .header-grey{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .black-word{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .blue-word{
		font-size: 24px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
	.navbar-default .navbar-nav > li > a{
		font-size: 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
}



//* Ipad Pro
@media (max-width:1366px) and (min-width:1024px) {
	.navbar.navbar-default{
		padding: 20px 0;
	}
	.screen-min-img{
		width: 650px;
		position: relative;
	}
	.video-js{
		max-height:450px;
	}
	.footer-nav-li{
		padding-right: 22px;
	}
	.group-subscribe .group-subscribe-button .subscribe{
		min-width: unset;
	}
	.background-video{
		padding: 0;
	}
}

//* Ipad
@media (max-width:1024px) and (min-width:768px) {
	.container-fluid .navbar-brand{
		margin-right: auto;
	}
	.navbar.navbar-default{
		padding: 20px 0;
	}
	.screen-min-img{
		width: 650px;
	}
	.background-video{
		padding: 0;
	}
	.container-fluid .collaborate .collaborate-header{
		font-size: 24px;
	}
	.container-fluid .collaborate .group-img .header-blue{
		font-size: 20px;
	}
	.manage-sub .manage-sub-header{
		font-size: 24px;
	}
	.group-grey .header-grey{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .black-word{
		font-size: 24px;
	}
	.group-subscribe .group-subscribe-word .blue-word{
		font-size: 24px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
}

@media only screen and (max-width: 320px){
	.content-2{
		padding: 0px 10px 0px 46px;
	}
	.content-3{
		padding: 0px 10px 0px 46px;
	}
	.content-4{
		padding: 0px 10px 0px 46px;
	}
}

//* Slider mobile
@media only screen and (max-width: 991px) {
	.border-two-side{
		display:none;
	}
	.border-two-side-left {
		display:none
	}
	.border-two-side-right {
		display:none
	}
	.border-middle{
		display:none;
	}
	.border-two-side-page{
		border: solid 2px rgba(7, 28, 64, 0.1);
		padding: 20px 10px 10px 10px;
		border-color: $azul;
		max-width: 322px;
	}
	.border-mid-side-page{
		border: solid 2px rgba(7, 28, 64, 0.1);
		padding: 20px 10px 10px 10px;
		border-color: $azul;
		max-width: 322px
	}
	.box-get-free{
		padding-top: 55px;
		.cta-free-box{
			margin: 0 auto;
			display: block;
			width: 90%;
			border: 1px;
			background-color: $azul;
			padding: 0;
			transition: all 0.3s linear;
		.overlay-free-btn{
			padding: 8px 24px;
			font-size: 20px;
			font-weight: 600;
			line-height: 1.6;
			color: $white;
			transition: all 0.3s linear;
		}
		&:hover{
			border-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
			.overlay-free-btn{
				background-color: rgba(245, 245, 245, 0.3);
				transition: all 0.3s linear;
			}
		}
		&:focus{
			border-color:#76abff;
			transition: all 0.3s linear;
			.overlay-free-btn{
				background-color: inherit;
				transition: all 0.3s linear;
			}
		}
		&:active{
			border-color:rgba(#000000, 0.15);
			transition: all 0.3s linear;
			.overlay-free-btn{
				background-color: rgba(#000000, 0.15);
				transition: all 0.3s linear;
			}
		}
	}
}
	.swiper-container {
		width: 100%;
		height: 680px;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

@media only screen and (min-width: 991px){
	.rectangle-bg-2{
		display:none;
	}
}


//* video Nexus 5X and 6P{
	@media (max-width:412px) and (min-width:375px) {
		.video-js{
			max-height: 255px;
		}
		.video-js{
			position: initial;
		}
		.screen-min-img{
			max-width: 295px;
		}
}

//* video Iphone 5{
	@media (max-width:320px) and (min-width:0px) {
		.video-js{
			max-height: 198px;
		}
		.video-js{
			position: initial;
		}
		.background-video{
			padding: unset!important;
		}
}

//* video Galaxy S5{
	@media (max-width:360px) and (min-width:321px) {
		.video-js{
			max-height: 223px;
		}
		.video-js{
			position: initial;
		}
		.background-video{
			padding: unset!important;
		}
}

//* video Iphone 6{
	@media (max-width:375px) and (min-width:361px) {
		.video-js{
			max-height: 232px;
		}
		.video-js{
			position: initial;
		}
		.screen-min-img{
			max-width: 295px;
		}
}

//* video Iphone 6 plus{
	@media (max-width:414px) and (min-width:413px) {
		.video-js{
			max-height: 256px;
		}
		.video-js{
			position: initial;
		}
		.screen-min-img{
			max-width: 295px;
		}
}

@media (max-width:1190px) and (min-width:980px){
	.group-subscribe .group-subscribe-button .subscribe{
		min-width: 100%;
		margin-bottom: 10px;
	}
	.group-subscribe .group-subscribe-button .box-subscribe{
		width: 100%;
	}
	.group-subscribe .group-subscribe-button .box-subscribe .cta-subscribe{
		width:100%;
	}
}

@media (max-width:1075px) and (min-width:991px){
		.container-fluid .background-splash .group-fre-register .bot-word{
			font-size: 12.5px;
		}
		.container-fluid .background-splash .group-fre-register .bot-blue{
			font-size: 13px;
		}
}

@media only screen and (max-width: 1200px){
	.video-offset{
		padding-left: 0;
		padding-right: 0;
	}
	#pricing{
		transform: translateY(5%);
	}
}

@media (max-width:991px) and (min-width:650px){
	.screens-min{
		width: 50%;
	}
	.free-register-all{
		width: 50%;
	}
	.screen-min-img{
		margin: auto;
		padding-top: 49px;
		max-width: 320px;
		height: 350px;
	}
	.container-fluid .background-splash .group-fre-register{
		padding-top:49px;
	}
	.container-fluid .background-splash .group-fre-register .fre-header{
		font-size: 24px;
	}
}

@media (max-width:780px) and (min-width:650px){
	.screen-min-img{
		margin-left: 0;
	}
}

@media only screen and (min-width: 767px){
	.manage-sub{
		margin-top: 40px;
	}
	.group-grey{
		margin-top: 40px;
	}
}

#mc_embed_signup_one .mc-field-group input {
          padding: 12px 10px;
          &:-ms-input-placeholder
          {
             color: rgba($night-blue, 0.25);
          }
		  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			 color: rgba($night-blue, 0.25);
		  }
		  &::-moz-placeholder { /* Firefox 19+ */
			color: rgba($night-blue, 0.25);
		  }
		  &:-moz-placeholder { /* Firefox 18- */
			color: rgba($night-blue, 0.25);
	}
}
	#mc_embed_signup .mc-field-group input{
		  &:-ms-input-placeholder
          {
             color: rgba($night-blue, 0.25);
          }
		  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			 color: rgba($night-blue, 0.25);
		  }
		  &::-moz-placeholder { /* Firefox 19+ */
			color: rgba($night-blue, 0.25);
		  }
		  &:-moz-placeholder { /* Firefox 18- */
			color: rgba($night-blue, 0.25);
	}
}

.subscribe{
		font-size: 16px;
		font-weight: 600;
		line-height: 1.43;
		background-color: $azul;
		vertical-align: middle;
		width: auto;
		padding: 0;
		border:	1px;
		border-radius: 2px;
		width: 96%;
		transition: all 0.3s linear;
		font-family: 'Poppins', sans-serif;
	.overlay-subscribe-btn{
		padding: 12px 25px;
		color: $white;
		width: 100%;
	}
	&:hover{
		border-color: rgba(245, 245, 245, 0.3);
		transition: all 0.3s linear;
		.overlay-subscribe-btn{
			background-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
		}
	}
	&:focus{
		border-color:#76abff;
		transition: all 0.3s linear;
		.overlay-subscribe-btn{
			background-color: inherit;
			transition: all 0.3s linear;
		}
	}
	&:active{
		border-color:rgba(#000000, 0.15);
		transition: all 0.3s linear;
		.overlay-subscribe-btn{
			background-color: rgba(#000000, 0.15);
			transition: all 0.3s linear;
		}

	}
}


@media (min-width: 1200px){
	.group-rectangle-bg{
		margin-left: 8.33333333%;
	}
}
@media (max-width: 991px){
	.group-rectangle-bg{
		padding-left: 0!important;
	}
}

.group-align{
	float:right;
}



.group-rectangle-bg{
	padding-left: 15px;
	padding-right: 0;
	position: relative;
    min-height: 1px;
}



.disable-class{
	opacity: 0;
	font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #186df1;
    margin-bottom: 10px;
}

.big-wrapper{
	padding-right: 30px;
}
.navbar-toggle{
	margin-right: 0;
	padding: 9px 15px;
}
@media only screen and (max-width: 767px){
	.group-align{
		float:none;
	}
	.big-wrapper{
		padding-right: 20px;
	}
	.imgs-koitech-2-res{
		position: relative;
		right: 30px;
	}
	.navbar-default .navbar-nav > li > a{
		font-size: 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	#pricing{
		transform: none;
	}
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
	border-color: transparent;
}

.box-get-slider{
    .cta-sider{
		padding: 0;
        margin: 0 auto;
		display: block;
		border: 1px;
		border-radius: 2px;
        width: 90%;
        background-color: $azul;
		transition: all 0.3s linear;
    }
    .overlay-sider-btn{
        padding: 8px 24px;
	    font-size: 20px;
		font-weight: 600;
		line-height: 1.6;
        color: $white;
		transition: all 0.3s linear;
    }
	&:hover{
		border-color: rgba(245, 245, 245, 0.3);
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
		}
	}
	&:focus{
		border-color:#76abff;
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: inherit;
			transition: all 0.3s linear;
		}
	}
	&:active{
		border-color:rgba(#000000, 0.15);
		transition: all 0.3s linear;
		.overlay-side-btn{
			background-color: rgba(#000000, 0.15);
			transition: all 0.3s linear;
		}
    &.active {
	    color: $white;
	    background-color: $azul;
		transition: all 0.3s linear;
    }
}
}

.modal-open{
	padding-right:17px !important;
}

.modal-dialog{
	width: inherit;
	margin:0;
}

.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
}

.fade-scale.in {
  opacity: 1;
  transform: scale(1);
}
.modal-content{
	margin-top: 88px;
	padding: 0 250px;
	border: 0;
	border-radius: 3px;
	background-color: #f7f7f7;
	box-shadow: 0 24px 32px 0 rgba(7, 28, 64, 0.1);
}
.modal-backdrop.in{
	opacity: 0;
}
.modal-header .close{
	margin-top: 0;
}
.close{
	font-size: 50px;
	font-weight: normal;
}

.btn.btn-default{
	width: 100%;
	padding: 0;
    margin: 0 auto;
	display: block;
	border-radius: 2px;
	border: 1px solid $azul;
    background-color: $white;
	transition: all 0.3s linear;
	color: $azul;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.6;
	.overlay-default-btn{
		padding: 8px 24px;
	}
	&:hover{
		background-color: $azul;
		color: $white;
		transition: all 0.3s linear;
			.overlay-default-btn{
				background-color: transparent;
			}
	  }
	&:focus{
		background-color: $azul;
		border: 1px solid #76abff;
		color: $white;
		transition: all 0.3s linear;
		.overlay-default-btn{
			background-color: inherit;
			transition: all 0.3s linear;
		}
	}
	&:active{
		background-color: $azul;
		color: $white;
		border-color:rgba(#000000, 0.15);
		transition: all 0.3s linear;
		.overlay-default-btn{
			background-color: rgba(#000000, 0.15);
			transition: all 0.3s linear;
		}
	}
}

.group-noti-new{
	background-color: $white;
	padding:0;
		.group-each{
			padding:0;
			margin-top: 100px;
				.side-left-instant{
					padding-left:0;
						.img-noti{
							width: 100%;
						}
				}
				.side-right-instant{
					padding-top: 5%;
						.blue-head{
							  font-size: 36px;
							  line-height: 1.5;
							  text-align: left;
							  color: $azul;
						}
						.content{
							  font-size: 14px;
							  line-height: 1.5;
							  text-align: left;
							  color: rgba($night-blue, 0.5);
						}
				}
				.side-left-tagging{
					padding-left:0;
					text-align: right;
						.img-noti{
							width: 100%;
						}
				}
				.side-right-tagging{
					padding-top: 5%;
						.blue-head{
							  font-size: 36px;
							  line-height: 1.5;
							  text-align: left;
							  color: $azul;
						}
						.content{
							  font-size: 14px;
							  line-height: 1.5;
							  text-align: left;
							  color: rgba($night-blue, 0.5);
						}
				}
		}
}

.group-noti-new-mobile{
	background-color: $white;
	display:none;
	padding:0;
		.group-each{
			padding:0;
			margin-top: 100px;
			text-align: center;
				.side-left-instant{
					.img-noti{
						width: 100%;
						max-width: 746px;
					}
				}
				.side-right-instant{
					margin-bottom: 10px;
						.blue-head{
							  font-size: 36px;
							  line-height: 1.5;
							  color: $azul;
						}
						.content{
							  font-size: 14px;
							  line-height: 1.5;
							  color: rgba($night-blue, 0.5);
						}
				}
		}
}

@media only screen and (max-width: 1200px){
	.group-noti-new-mobile{
		display: block;
	}
	.group-noti-new{
		display: none;
	}
}

#pricing{
	box-shadow: inset 0 2px 0 0 rgba(7, 28, 64, 0.1);
}

@media only screen and (max-width: 1200px){
	.modal-content {
		padding: 0 150px;
	}
}

@media only screen and (max-width: 767px){
	.modal-content {
		padding: 0 80px;
		margin-top: 48px;
	}
	.nav.navbar-nav.navbar-right .sign-up{
		&:last-child{
			margin-top: 10px;
			padding-left:0;
		}
	}
}

//* Mobile popup
@media only screen and (max-width: 736px){
	.modal-content {
		padding: 0 20px;
	}
}

// Hamb navigation button
.navbar-toggle .icon-bar:nth-of-type(2) {
	  top: 1px;
}

.navbar-toggle .icon-bar:nth-of-type(3) {
  	top: 2px;
}

.navbar-toggle .icon-bar {
	  position: relative;
	  transition: all 0.3s ease-in-out;
}

.navbar-toggle.active .icon-bar:nth-of-type(1) {
	  top: 6px;
	  transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
	  background-color: transparent;
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
	  top: -6px;
	  transform: rotate(-45deg);
}


@media only screen and (max-width: 767px){
	.navbar-fixed-bottom .navbar-collapse, .navbar-collapse{
		min-height: 1000px;
	}
}

@media only screen and (min-width: 767px){
	.navbar-fixed-bottom .navbar-collapse, .navbar-collapse{
		min-height: auto;
	}
}

@media only screen and (max-width: 825px){
	.nav>li>a{
		padding: 15px 10px;
	}
}


@media only screen and (max-width: 852px){
	.nav>li>a{
		padding: 15px 7px;
	}
}

@media only screen and (max-width: 787px){
	.nav>li>a{
		padding: 15px 4px;
	}
}

@media only screen and (max-width: 767px){
	#nav-1{
		display:none !important;
	}
	#nav-2{
		display:none !important;
	}
}

body.modal-open{
       padding-right: 0!important;
       overflow-y:scroll;
       position: fixed;
	   width:100%;
}

.modal-header{
	padding: 35px 0;
}

.modal-backdrop.in{
	display:none;
}

#nav-1{
	display:none;
}

.modal-body{
	padding:0;
}


#myModal{
	&.modal{
		.wrapper{
			position: fixed;
			height: 100%;
			width: 100%;
			background: transparent;
		}
	}
}
#myPopup{
	 &.modal{
		padding-right: 0 !important;
		overflow:hidden;
			.wrapper{
				position: fixed;
				height: 100%;
				width: 100%;
				background: transparent;
			}
	}
	.modal-dialog{
		height:100%;
			.modal-content{
				height:100%;
				min-height: 1130px;
					
			}
			.modal-header{
				margin-bottom: 30px;
					.modal-title{
						text-align: center;
						font-weight: 600;
					}
			}
			.modal-body{
				
				.mc-field-group{
					padding-bottom: 0 !important;
					min-height: 95px;
					> label{
						 font-family:  $Averta;
						 font-size: 12px;
						 line-height: 1.5;
						 letter-spacing: normal;
						 text-align: left;
						 color: rgba($night-blue, 0.5);
						}
						input{
							 border: 1px solid rgba($night-blue, 0.25);
						}
						strong{
							 padding:0;
							 font-family:  $Averta;
							 font-size: 12px;
							 line-height: 1.5;
							 letter-spacing: normal;
							 text-align: left;
							 color: rgba($night-blue, 0.5);
						}
						&.input-group{
							ul{
								.styled-checkbox {
								  position: absolute; // take it out of document flow
								  opacity: 0; // hide it

								  & + label {
									position: relative;
									cursor: pointer;
									padding: 0;
									font-weight: 300;
								  }

								  // Box.
								  & + label:before {
									content: '';
									margin-right: 10px;
									display: inline-block;
									vertical-align: sub;
									width: 20px;
									height: 20px;
									background: $white;
									border: 2px solid rgba($night-blue, 0.25);
								  }

								  // Box hover
								  &:hover + label:before {
									background: transparent;
								  }
  
								  // Box focus
								  &:focus + label:before {
									box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
								  }

								  // Box checked
								  &:checked + label:before {
									background: $white;
									border-color: $azul;
								  }
  

								  // Checkmark. Could be replaced with an image
								  &:checked + label:after {
									content: '';
									position: absolute;
									left: 5px;
									top: 6px;
									background: $azul;
									width: 2px;
									height: 2px;
									box-shadow: 
									  2px 0 0 $azul,
									  4px 0 0 $azul,
									  4px -2px 0 $azul,
									  4px -4px 0 $azul,
									  4px -6px 0 $azul,
									  4px -8px 0 $azul;
									transform: rotate(45deg);
								  }
								}
							
							label{
								  font-size: 14px;
								  line-height: 1.43;
								  text-align: left;
								  color: $night-blue;
							}
							}
						}
						label{
							svg{
								width: 16px;
								height: 16px;
								vertical-align: sub;
								fill:  $lipstick;
							}
						}
				}
				.apply{
					font-family: 'Poppins', sans-serif;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.5;
					text-align: center;
					color: $white;
					background-color:$azul;
					padding: 0;
					width: 100%;
					border: 1px;
					border-radius: 2px;
						.overlay-btn{
							padding: 8px 25px;
						}
						&:hover{
							border-color: rgba(245, 245, 245, 0.3);
							transition: all 0.3s linear;
								.overlay-btn{
									background-color: rgba(245, 245, 245, 0.3);
									transition: all 0.3s linear;
								}
							}
								&:focus{
									border-color:#76abff;
									transition: all 0.3s linear;
										.overlay-btn{
											background-color: inherit;
											transition: all 0.3s linear;
										}
								}
								&:active{
									border-color:rgba(#000000, 0.15);
									transition: all 0.3s linear;
										.overlay-btn{
											background-color: rgba(#000000, 0.15);
											transition: all 0.3s linear;
										}
									}
								}

			}
	}
}


@media only screen and (max-width: 1600px){
	#myPopup .modal-dialog .modal-content{
		min-height: 1000px;
	}
}

@media only screen and (max-width: 1000px){
	#myPopup .modal-dialog .modal-content{
		min-height: 980px;
	}
}


#idIframe{
	width: 100%;
    border: 0;
	height: 350px;
	position: absolute;
	right: 0;
	padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width:991px){
	#idIframe{
		position: inherit;
		height: 275px;
	}
}

.change-color{
	background-color: $white !important;
	background-image: none !important;
	opacity: inherit !important;
}

.change-li{
	color: $night-blue !important;
	&:hover{
		color: rgba($night-blue, 0.5) !important;
	}
}

.change-login-btn{
	color: $azul !important;
	background-color: $white !important;
	border: 1px solid $azul !important;
}

#nav-2{
	display: none;
}
#nav-1{
	display: block;
}
.modal-backdrop{
	top: 88px;
}


@media only screen and(max-width: 767px){
	.navbar.navbar-default{
		background-color: $white !important;
		background-image: none !important;
		opacity: inherit !important;
		position: fixed;
		right: 0;
		left: 0;
		z-index: 1030;
	}
	.nav.navbar-nav.navbar-right .cta-primary-white{
		color: $azul;
		border-color: $azul;
	}
	.navbar-default .navbar-nav > li > a{
		 color: #071c40 !important;
	}
	
}

// Css mailchimp

#mc_embed_signup {
      background: transparent;
      clear: left;
      font-family: 'Open Sans', sans-serif;
}

#mc_embed_signup .mc-field-group input {
       outline: none;
       border: 0;
 }

 #mc_embed_signup .mc-field-group {
       width: 100% !important;
       font-family: 'Open Sans', sans-serif;
 }

 #mc_embed_signup input.email {
      width: 100%;
       min-height: 40px;
       border: 0;
       outline: none;
       margin: 0;
}

#mc_embed_signup form {
       padding: 0 !important;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
     background-color: transparent !important;
	 color: red !important;
	 margin: 0 !important;
	 padding: 0 !important;
     width:100%;
	 font-family: 'Poppins', sans-serif;
}

#mc_embed_signup #mce-success-response {
      text-align: center;
      width: 100%;
}
#mc_embed_signup div.response{
      width: 100% !important;
      margin: auto !important;
}

#mc_embed_signup div#mce-responses {
       padding: 0;
       margin: 0 !important;
       width: 100% !important;
       padding: 0 .5em .5em 0 !important;
}


// mailchimp popup
			#mc_embed_signup {
                background: transparent;
                clear: left;
                font-family: 'Open Sans', sans-serif;
            }

            #mc_embed_signup .mc-field-group input {
                outline: none;
                border: 0;
            }

            #mc_embed_signup .mc-field-group {
                width: 100% !important;
                font-family: 'Open Sans', sans-serif;
            }

            #mc_embed_signup input.email {
                width: 100%;
                min-height: 40px;
                border: 0;
                outline: none;
                margin: 0;
            }

            #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
                background-color: transparent !important;
                width: 100%;
            }

            #mc_embed_signup #mce-success-response {
                text-align: center;
                width: 100%;
				font-family: 'Poppins', sans-serif;
            }

            #mc_embed_signup div.response {
                width: 100% !important;
                margin: auto !important;
            }

            #mc_embed_signup div#mce-responses {
                padding: 0;
                margin: 0 !important;
                width: 100% !important;
                padding: 0 .5em .5em 0 !important;
            }

            #mc_embed_signup .mc-field-group.input-group input {
                margin-right: 10px;
				border: 1px solid $azul;
            }

            #mc_embed_signup input[type=checkbox] {
                width: 19px !important;
                height: 19px;
                margin: -2px 10px 0 0;
                vertical-align: middle;
                cursor: pointer;
            }
						#mc_embed_signup .mc-field-group.input-group input{
							&:focus{
								outline: 2px solid black;
							}
							&:active{
								outline: 2px solid black;
							}
						}
/*form 2*/
       #mc_embed_signup_one {
          background: transparent;
          clear: left;
          font-family: 'Poppins', sans-serif;
       }

       #mc_embed_signup_one .button {
            width: 96%;
            background-color: #186df1;
            height: 45px;
            font-family: 'Poppins', sans-serif;
       }

       #mc_embed_signup_one .mc-field-group input {
            padding: 12px 10px;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            width: 96%;
			margin-bottom: 3%;
            outline: none;
            border-radius: 4px;
            border: 1px solid darkgray;
       }

       #mc_embed_signup_one div.response {
             width: 100%;
       }

       #mc_embed_signup_one div#mce-responses {
             margin: 0;
             padding: 0;
             //width: 96%;
       }

       #mc_embed_signup_one div.response {
             padding: 0;
       }

       #mc_embed_signup_one .mc-field-group {
			 padding-bottom: 0;
             width: initial;
       }


#sub-two{
	width: 100%;
    border: 0;
    height: 230px;
	background-color: transparent !important;
}

.modal-footer{
	padding: 15px 0 15px 0;
}

.mce_inline_error{
	color: red;
	margin-bottom: 10px;
	font-family: 'Poppins', sans-serif;
}

#mce-success-response{
	color: green;
	margin-bottom: 10px;
	font-family: 'Poppins', sans-serif;
}

#mc_embed_signup_one .mc-field-group input{
	color: $night-blue;
}

#mc_embed_signup .mc-field-group input{
	color: $night-blue;
	border: 1px solid darkgray;
	font-family: 'Poppins', sans-serif;
}

#mc_embed_signup div.response{
	padding: 0 !important;
}

#mc_embed_signup #mce-error-response{
	color:red;
	font-family: 'Poppins', sans-serif;
	a{
		color:red;
		display:none;
		&:hover{
			text-decoration: underline;
		}
	}
}

#mc_embed_signup_one #mce-error-response{
	color:red;
	margin-bottom: 10px;
	a{
		color:red;
		display:none;
		&:hover{
			text-decoration: underline;
		}
	}
}


.pre-regis-box{
	font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: $white;
    background-color:$azure;
    padding: 0;
    width: 100%;
    border: 1px;
	border-radius: 2px;
		.overlay-btn{
			padding: 8px 25px;
		}
		&:hover{
			border-color: rgba(245, 245, 245, 0.3);
			transition: all 0.3s linear;
				.overlay-btn{
					background-color: rgba(245, 245, 245, 0.3);
					transition: all 0.3s linear;
				}
			}
				&:focus{
					border-color:#76abff;
					transition: all 0.3s linear;
						.overlay-btn{
							background-color: inherit;
							transition: all 0.3s linear;
						}
				}
				&:active{
					border-color:rgba(#000000, 0.15);
					transition: all 0.3s linear;
						.overlay-btn{
							background-color: rgba(#000000, 0.15);
							transition: all 0.3s linear;
						}
					}
				}



@media only screen and (max-width: 1199px){
	.group-noti-new-mobile .group-each .side-right-instant .content{
		max-width: 450px;
		margin: auto;
	}
}

// IE only
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
	  .modal-open .modal{
			padding-left:0 !important;
	  }
}


#modalVideo{
	padding-right: 0 !important;
	height: 100%;
    min-height: 550px;
	overflow-y: hidden;
		.modal-dialog{
			height: 100%;
				.modal-header{
					border-bottom: 0;
				}
				.modal-content{
					margin-top: 0;
					height:100%;
					min-height: 1130px;
					background: rgba(0,0,0,0.8);
						.modal-body{
							iframe{
								
							}
						}
						.modal-header{
							.close{
								color: $white;
								opacity: 1;
									&:hover{
										opacity: 0.5;
									}
							}
						}
				}
				.modal-footer{
					border-top: 0;
				}
		}
}


@media only screen and (max-width: 1200px ){
	#modalVideo .modal-dialog .modal-content{
		padding: 0 80px;
	}
}

@media only screen and (max-width: 1000px){
	#myPopup .modal-dialog .modal-content{
		padding: 0 80px;
	}
}

@media only screen and (max-width:650px){
	#myPopup .modal-dialog .modal-content{
		padding: 0 15px;
	}
}

@media only screen and (max-width: 1600px){
	#modalVideo .modal-dialog .modal-content{
		min-height: 900px;
	}
}

@media only screen and (max-width: 767px){
	#myPopup.modal{
		overflow:auto;
	}
	#myPopup #mc_embed_signup .mc-field-group.input-group ul{
		padding:0;
	}
	#myPopup .modal-dialog .modal-body .mc-field-group{
		min-height: 80px;
	}
}


