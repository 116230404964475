//Font
@mixin font-face($font-name, $fonts-path, $fonts-version) {
    font-family: $font-name;
    src: url('#{$fonts-path}.eot#{$fonts-version}');
    src: url('#{$fonts-path}.eot?#iefix#{$fonts-version}') format('embedded-opentype'),
    	   url('#{$fonts-path}.woff#{$fonts-version}') format('woff'),
         url('#{$fonts-path}.ttf#{$fonts-version}')  format('truetype'),
         url('#{$fonts-path}.svg##{$font-name}') format('svg');
    font-weight: normal;
    font-style: normal;
}



//Media Query
@mixin respond-to($media) {
    @media only screen and #{$media} {
        @content;
    }
}
