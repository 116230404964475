@charset "UTF-8";
//Font
$AvertaBold:'Averta Bold', Helvetica, Arial,sans-serif;
$AvertaRegularItalic:'Averta Regular Italic', Helvetica, Arial,sans-serif;
$AvertaSemibold:'Averta Semibold', Helvetica, Arial,sans-serif;
$Averta:'Averta', Helvetica, Arial,sans-serif;

// Load fonts from this directory
$fonts-path-AvertaBold: "../font/path-Averta Bold";
$fonts-path-AvertaRegularItalic: "../font/Averta Regular Italic";
$fonts-path-AvertaSemibold: "../font/Averta Semibold";
$fonts-path-Averta: "../font/Averta";

$fonts-version: '?v=1.000';

@font-face{
    @include font-face('AvertaBold',$fonts-path-AvertaBold, $fonts-version);
}
@font-face{
    @include font-face('AvertaRegularItalic',$fonts-path-AvertaRegularItalic, $fonts-version);
}
@font-face{
    @include font-face('AvertaSemibold',$fonts-path-AvertaSemibold, $fonts-version);
}

@font-face{
    @include font-face('Averta',$fonts-path-Averta, $fonts-version);
}



